import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import '../mapstyles.css';
import {Marker} from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import casablanca from '../../images/casablanca.jpg';
import chefchaouen1 from "../../images/chefchaouen1.jpg";
import chefchaouen2 from "../../images/chefchaouen2.jpg";

import yerevanrio from "../../images/travels2023/yerevanrio.png";

import tbilisicenter from "../../images/travels2023/tbilisicenter.png";
import tbilisipano from "../../images/travels2023/tbilisipano.png";
import tbilisifabrics from "../../images/travels2023/tbilisifabrics.png";
import tbilisibridge from "../../images/travels2023/tbilisibridge.png";

import tbilisicats from "../../images/travels2023/tbilisicats.png";
import tbilisicat from "../../images/travels2023/tbilisicat.png";
import tbilisibmw from "../../images/travels2023/tbilisibmw.png";

import tbilisiadjara from "../../images/travels2023/tbilisiadjara.png";
import tbilisifest from "../../images/travels2023/tbilisifest.png";
import tbilisicafe from "../../images/travels2023/tbilisicafe.png";
import tbilisimothergod from "../../images/travels2023/tbilisimothergod.png";


import tbilisigori from "../../images/travels2023/tbilisigori.png";
import tbilisigorifortress from "../../images/travels2023/tbilisigorifortress.png";


import borjomimtsheta from "../../images/travels2023/borjomimtsheta.png";

import mtshetawtf from "../../images/travels2023/mtshetawtf.png";
import mtshetaselfie from "../../images/travels2023/mtshetaselfie.png";

import borjomibakuriani from "../../images/travels2023/borjomibakuriani.png";
import borjomime from "../../images/travels2023/borjomime.png";
import borjomibridge from "../../images/travels2023/borjomibridge.png";
import borjomibridge1 from "../../images/travels2023/borjomibridge1.png";
import borjomiwater from "../../images/travels2023/borjomiwater.png";
import borjomidog from "../../images/travels2023/borjomidog.png";


import bakuRocks from "../../images/travels2023/bakuRocks.jpg";
import bakuFire from "../../images/travels2023/bakuFire.jpg";
import bakuStreet from "../../images/travels2023/bakuStreet.jpg";
import bakuSea from "../../images/travels2023/bakuSea.jpg";

import ArmeniaArarat from "../../images/travels2023/ArmeniaArarat.jpg";
import ArmeniaLake from "../../images/travels2023/ArmeniaLake.jpg";
import ArmeniaHotel from "../../images/travels2023/ArmeniaHotel.jpg";






import istambulaya from "../../images/travels2023/istambulaya.png";
import istambulcity from "../../images/travels2023/istambulcity.png";
import istambulbosphorus from "../../images/travels2023/istambulbosphorus.png";
import istambulcat from "../../images/travels2023/istambulcat.png";
import istambulpano from "../../images/travels2023/istambulpano.png";
import istambulstreet from "../../images/travels2023/istambulstreet.png";
import istambultram from "../../images/travels2023/istambultram.png";


import dubaiburj from "../../images/travels2023/dubaiburj.png";
import dubaiexpo from "../../images/travels2023/dubaiexpo.png";
import dubaifinancial from "../../images/travels2023/dubaifinancial.png";
import dubainight from "../../images/travels2023/dubainight.png";
import dubaiPanda from "../../images/travels2023/dubaiPanda.png";
import dubaihongkong from "../../images/travels2023/dubaihongkong.png";












import {Link} from "gatsby";





mapboxgl.accessToken =
    'pk.eyJ1IjoidXNlcnBhdiIsImEiOiJja24zZmhxODAwOGpoMnZvMGpnZmtrNnhiIn0.62IOjLEwae3VzgbXme8MNg';
// mine is pk.eyJ1IjoidXNlcnBhdiIsImEiOiJja3AyanpkZGYwNmNjMnVta25scmhob3MxIn0.DUIm9sqf6hql4AYqIeSqBg
// mine default
// default is pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA

const Map = () => {
    const mapContainerRef = useRef(null);

    const [lng, setLng] = useState(13.0);
    const [lat, setLat] = useState(46.0);
    const [zoom, setZoom] = useState(4.5);

    // Initialize map when component mounts
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom,
            pitch: 0,
            accessToken: 'pk.eyJ1IjoidXNlcnBhdiIsImEiOiJja24zZmhxODAwOGpoMnZvMGpnZmtrNnhiIn0.62IOjLEwae3VzgbXme8MNg'
        });

        const chapters = {
            'empty': {
                bearing: 0,
                center: [14.59, 46.056946],
                zoom: 2,
                pitch: 0
            },
            'yerevan': {
                bearing: 0,
                center: [44.5082873,40.1868012],
                zoom: 9,
                pitch: 0
                },
            'tbilisi': {
                bearing: 0,
                center: [44.801398,41.7313096],
                zoom: 8,
                pitch: 29
            },
            'mtsheta': {
            bearing: 0,
                center: [44.7461413, 41.8399581],
                zoom: 13.7,
                pitch: 8
            },
            'gori': {
                bearing: 0,
                center: [44.12, 41.9853448],
                zoom: 13.78,
                pitch: 8
            },
            'borjomi': {
                bearing: 0,
                center: [43.3905326, 41.8404494],
                zoom: 13,
                pitch: 10
            },
            'istambul': {
                bearing: 0,
                center: [28.9966717,41.0234281],
                zoom: 10,
                pitch: 0
            },
            'dubai': {
                bearing: 0,
                center: [55.1824471,25.2093476],
                zoom: 9,
                pitch: 0
            },
            'baku': {
                bearing: 0,
                center: [50.0462229,40.3638054],
                zoom: 10,
                pitch: 20
            },
            'armenia': {
                bearing: 0,
                center: [45.436035,40.2366961],
                zoom: 9,
                pitch: 0
            },
            'slovenia': {
                bearing: 0,
                center: [14.99, 46.056946],
                zoom: 7,
                pitch: 5
            },
            'jeddah': {
                duration: 6000,
                center: [39.198, 21.5424],
                bearing: 110,
                zoom: 7,
                pitch: 50
            },
            'chefchaouen': {
                duration: 6000,
                center: [-5.068376, 35.168780],
                bearing: 0,
                zoom: 8,
                pitch: 75
            },
            'casablanca': {
                duration: 6000,
                center: [-7.654668502518901, 33.61016774793188],
                bearing: 70,
                zoom: 6.5,
                pitch: 30
            },
            'dakar': {
                bearing: 90,
                center: [-17.4441, 14.6937],
                zoom: 10,
                speed: 0.6,
                pitch: 40
            },
            'paderborn': {
                bearing: 90,
                center: [8.79439,51.7191],
                zoom: 12.5
            },
            'charing-cross': {
                bearing: 90,
                center: [0.12416858, 51.50779757],
                zoom: 5,
                pitch: 20
            }
        };

        let activeChapterName = 'slovenia';
        function setActiveChapter(chapterName) {
            if (chapterName === activeChapterName) return;

            map.flyTo(chapters[chapterName]);

            document.getElementById(chapterName).classList.add('active');
            document.getElementById(activeChapterName).classList.remove('active');

            activeChapterName = chapterName;
        }

        function isElementOnScreen(id) {
            const element = document.getElementById(id);
            const bounds = element.getBoundingClientRect();
            return bounds.top < window.innerHeight && bounds.bottom > 0;
        }

// On every scroll event, check which element is on screen
        window.onscroll = () => {
            for (const chapterName in chapters) {
                if (isElementOnScreen(chapterName)) {
                    setActiveChapter(chapterName);
                    break;
                }
            }
        };


        

        map.on('load', () => {

            // map.addSource('places', {
            //         'type': 'geojson',
            //         'data': {
            //             'type': 'FeatureCollection',
            //             'features': [
            //
            //             ]
            //         }
            //     },
                map.addSource('mapbox-dem', {
                    'type': 'raster-dem',
                    'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                    'tileSize': 512,
                    'maxzoom': 14
                })
                map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 })

                map.addLayer({
                    'id': 'sky',
                    'type': 'sky',
                    'paint': {
// set up the sky layer to use a color gradient
                        'sky-type': 'gradient',
// the sky will be lightest in the center and get darker moving radially outward
// this simulates the look of the sun just below the horizon
                        'sky-gradient': [
                            'interpolate',
                            ['linear'],
                            ['sky-radial-progress'],
                            0.8,
                            'rgba(135, 206, 235, 1.0)',
                            1,
                            'rgba(0,0,0,0.1)'
                        ],
                        'sky-gradient-center': [0, 0],
                        'sky-gradient-radius': 90,
                        'sky-opacity': [
                            'interpolate',
                            ['exponential', 0.1],
                            ['zoom'],
                            5,
                            0,
                            22,
                            1
                        ]
                    }
                }
                // ) Was a mistake here, uncomment in case of issues


            );
// Add a layer showing the places.
//             map.addLayer({
//                 'id': 'places',
//                 'type': 'circle',
//                 'source': 'places',
//                 'paint': {
//                     'circle-color': '#4264fb',
//                     'circle-radius': 6,
//                     'circle-stroke-width': 2,
//                     'circle-stroke-color': '#ffffff'
//                 }
//             });

// Create a popup, but don't add it to the map yet.
            const popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            });

            map.on('mouseenter', 'places', (e) => {
// Change the cursor style as a UI indicator.
                map.getCanvas().style.cursor = 'pointer';

// Copy coordinates array.
                const coordinates = e.features[0].geometry.coordinates.slice();
                const description = e.features[0].properties.description;

// Ensure that if the map is zoomed out such that multiple
// copies of the feature are visible, the popup appears
// over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

// Populate the popup and set its coordinates
// based on the feature found.
                popup.setLngLat(coordinates).setHTML(description).addTo(map);
            });

            map.on('mouseleave', 'places', () => {
                map.getCanvas().style.cursor = '';
                popup.remove();
            });
        });







        /////////////////////////

        map.on('move', () => {
            setLng(map.getCenter().lng.toFixed(4));
            setLat(map.getCenter().lat.toFixed(4));
            setZoom(map.getZoom().toFixed(2));
        });

//
// // create DOM element for the marker
//         var el = document.createElement('div');
//         el.id = 'marker';

// // create the marker
//         new mapboxgl.Marker(el)
//             .setLngLat([12.12567, 46.575])
//             .setPopup(popup) // sets a popup on this marker
//             .addTo(map);



        // Clean up on unmount
        return () => map.remove();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>

            <div className='map-container' ref={mapContainerRef} id='map'> </div>

            <div id="features">

                <h1 style={{marginTop: `100px`, marginLeft: `auto`, marginRight:`auto`, textAlign: `auto`}}>
                    2023</h1>
                <p style={{marginLeft: `auto`, marginRight:`auto`, textAlign: `auto`}}>
                    <i>Not optimized for smartphones</i></p>

                <section id="empty">
                    <h3></h3>
                    <p>
                        <img src="https://img.icons8.com/ios/250/000000/region-code.png"/>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </p>
                </section>

                {/*adding 2023 travels */}
                <section id="yerevan" className="active">
                    <h3>Yerevan</h3>
                    <h4>January 2023</h4>

                        <img src={yerevanrio} style={{borderRadius:`75px`}} />

                </section>



                <section id="tbilisi" className="active">
                    <h3>Tbilisi</h3>
                    <h4>January 2023</h4>

                    <img src={tbilisicenter} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={tbilisipano} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={tbilisifabrics} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={tbilisibridge} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={tbilisicats} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={tbilisiadjara} style={{borderRadius:`75px`}} />
                    <br />
                </section>

                <section id="mtsheta" className="active">
                    <h3>Mtskheta</h3>
                    <h4>January 2023</h4>

                    <img src={mtshetawtf} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={mtshetaselfie} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={borjomimtsheta} style={{borderRadius:`75px`}} />
                    <br />
                </section>

                <section id="gori" className="active">
                    <h3>Gori</h3>
                    <h4>January 2023</h4>

                    <img src={tbilisigori} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={tbilisigorifortress} style={{borderRadius:`75px`}} />
                    <br />


                </section>

                <section id="borjomi" className="active">
                    <h3>Borjomi</h3>
                    <h4>January 2023</h4>

                    <img src={borjomibakuriani} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={borjomime} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={borjomiwater} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={borjomidog} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={borjomibridge} style={{borderRadius:`75px`}} />
                    <br />

                </section>



                <section id="istambul" className="active">
                    <h3>Istambul</h3>
                    <h4>January 2023</h4>
                    <br />
                    <img src={istambulcat} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={istambulpano} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={istambulaya} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={istambulcity} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={istambultram} style={{borderRadius:`75px`}} />

                </section>

                <section id="dubai" className="active">
                    <h3>Dubai</h3>
                    <h4>March 2023</h4>
                    <br />
                    <img src={dubaiPanda} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={dubaiburj} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={dubaifinancial} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={dubainight} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={dubaiexpo} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={dubaihongkong} style={{borderRadius:`75px`}} />

                </section>


                <section id="baku" className="active">
                    <h3>Baku</h3>
                    <h4>June 2023</h4>
                    <br />
                    <img src={bakuSea} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={bakuStreet} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={bakuRocks} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={bakuFire} style={{borderRadius:`75px`}} />
                    <br />
                </section>

                <section id="armenia" className="active">
                    <h3>Armenia</h3>
                    <h4>August 2023</h4>
                    <br />
                    <img src={ArmeniaArarat} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={ArmeniaHotel} style={{borderRadius:`75px`}} />
                    <br />
                    <img src={ArmeniaLake} style={{borderRadius:`75px`}} />
                    <br />
                </section>


                <h2>Delayed plans</h2>

                <section id="slovenia" className="active">
                    <h3>Slovenia</h3>
                    <h4>Visited</h4>
                        <ul style={{listStyle:`none`}}>
                            <li>Lyubljana</li>
                            <li>Lake Bled</li>
                            <li>Polhograjski Dolomiti</li>
                            <li>my friends in Kamnik</li>
                            <li>and many more</li>
                        </ul>

                </section>
                <section id="jeddah">
                    <h3>Jeddah, Saudi Arabia</h3>
                    <h4>2023-2024</h4>

                    <p>
                        Filming <a href="https://en.wikipedia.org/wiki/Jeddah_Tower">Jeddah Tower</a> with FPV drone.
                        <br />
                        Hopefully, visiting Neom region and all the north of the country.
                        <br />
                    </p>
                </section>

                <section id="chefchaouen">
                    <h3>Chefchaouen</h3>
                    <h4>2023</h4>

                    <p>
                        The Blue City<br/>
                    </p>
                    <p>
                        <br/>
                        <br/>
                    </p>
                    <img src={chefchaouen1} style={{borderRadius:`75px`}}/>
                    <p>
                        <br/>
                        <br/>
                    </p>
                    <img src={chefchaouen2} style={{borderRadius:`75px`}}/>
                </section>

                <section id="casablanca">
                    <h3>Casablanca</h3>
                    <h4>Spring 2024</h4>

                    <p>
                        Interchange for the next destination. Should be some time for visiting the city. <br />
                        Anyway, a good place. Ocean, colors and moroccan fairy tales. <br/>
                    </p>
                    <p>
                        <br/>
                        <br/>
                    </p>
                    <img src={casablanca} style={{borderRadius:`75px`}}/>
                </section>

                <section id="dakar">
                    <h3>Dakar</h3>
                    <h4>Spring 2024</h4>

                    <p>
                        Interesting place recommended by many people. Main port of Atlantic smuggling and lost ships.
                        Take a look somewhere (not here) what does it mean. Its port is also starred in <a href="https://www.imdb.com/title/tt8332438/">ZeroZeroZero</a> series.
                        <br />
                        Port of Dakar - place where you can buy Hublot watches for 100 euro. <br />
                        Some vaccines are needed for this region.
                    </p>
                </section>
                <section id="paderborn">
                    <h3>Paderborn</h3>
                    <h4>Summer 2024</h4>
                    <p>
                        Visiting f
                    </p>
                    <br />
                    <br />
                    <img src="https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/128/000000/external-family-moving-house-inipagistudio-mixed-inipagistudio.png"/>
                    <br />
                    <br />
                </section>
                <section id="charing-cross">
                    <h3>So far that's it</h3>
                    <p>
                        Will be more
                    </p>
                    <small id="citation">
                        Created with care just for fun
                        {/*Adapted from <a href="http://www.gutenberg.org/files/2346/2346-h/2346-h.htm">Project Gutenberg</a>*/}
                    </small>
                </section>
            </div>
        </div>
    );
};

export default Map;